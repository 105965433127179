import React from 'react';
import './App.scss';

import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyC8c2f1O4-nQQy0vgw0l7rzpdz_rXBgmAk",
  authDomain: "ericsmiley-c8228.firebaseapp.com",
  projectId: "ericsmiley-c8228",
  storageBucket: "ericsmiley-c8228.appspot.com",
  messagingSenderId: "532964465194",
  appId: "1:532964465194:web:8fd6311ffbd1b179ce4153",
  measurementId: "G-GHMH3CL4ES"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className='My-name'><span className='First-name'>eric</span><span className='Last-name'>smiley</span></h1>
      </header>
    </div>
  );
}

export default App;
